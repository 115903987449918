@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa+One&display=swap');

body {
   background-color:hsla(0,0%,0%,1);
background-image:
radial-gradient(at 10% 0%, hsla(212,100%,50%,1) 0px, transparent 50%),
// radial-gradient(at 0% 100%, hsla(139,100%,50%,1) 0px, transparent 50%),
radial-gradient(at 100% 0%, hsla(290,100%,50%,0.92) 0px, transparent 50%);
}
a {
    color: black;
}

.icons {
    color: #c597bf;
    padding: 5px;
}

h3 {
    display: flex;
    justify-self: flex-start;
}

.home-h2 {
    font-size: 2rem;
    font-family: 'Changa One', cursive;
    -webkit-text-stroke: 1px #24d1ef;
    text-shadow: 0px 2px 4px rgb(55, 0, 255);
}
.projects-text {
    
    // position: relative;
    text-transform: uppercase;
    text-shadow: -15px 5px 20px #dc06f4;
    color: rgb(2, 2, 2);
    letter-spacing: -0.05em;
    font-family: 'Anton', Arial, sans-serif;
    user-select: none;
    text-transform: uppercase;
    font-size: 150px;
    transition: all 0.25s ease-out;
    margin-top: -40px;
    -webkit-text-stroke: 3px #f29ceb;
}


.about-text {
    text-transform: uppercase;
    text-shadow: -15px 5px 20px #2606f4;
    color: rgb(2, 2, 2);
    letter-spacing: -0.05em;
    font-family: 'Anton', Arial, sans-serif;
    user-select: none;
    text-transform: uppercase;
    font-size: 150px;
    transition: all 0.25s ease-out;
    margin-top: -40px;
    -webkit-text-stroke: 3px #49c4f1;
}

.home-img {
    height: 500px;
    width: 400px;
    border-radius: 80px;
    background: #eee2ec;
    box-shadow:  -5px -5px 10px #00d9fb,
    5px 5px 10px #de09ed;
    
}

.skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.about-img {
    height: 100%;
    width: 35%;
    border-radius: 20px;
    box-shadow:  -5px -5px 10px #00d9fb,
    5px 5px 10px #de09ed;
   
    
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --color-primary: #06fcf4;
  --color-secondary: #e090f4;
  --color-tertiary: #f27dc5;
  --color-quaternary: #f07a6a;
  --color-quinary: #ee6352;
}


.content {
  display: flex;
  align-content: center;
  justify-content: center;
  
}

.text_shadows {
  text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary),
    9px 9px var(--color-quaternary), 12px 12px 0 var(--color-quinary);
  font-family: bungee, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: calc(2rem + 5vw);
  text-align: center;
  margin: 0;
  color: var(--color-primary);
  animation: shadows 1.2s ease-in infinite, move 1.2s ease-in infinite;
  letter-spacing: 0.4rem;
}

@keyframes shadows {
  0% {
    text-shadow: none;
  }
  10% {
    text-shadow: 3px 3px 0 var(--color-secondary);
  }
  20% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary);
  }
  30% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
  }
  40% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
      12px 12px 0 var(--color-quinary);
  }
  50% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
      12px 12px 0 var(--color-quinary);
  }
  60% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary),
      12px 12px 0 var(--color-quinary);
  }
  70% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary), 9px 9px var(--color-quaternary);
  }
  80% {
    text-shadow: 3px 3px 0 var(--color-secondary),
      6px 6px 0 var(--color-tertiary);
  }
  90% {
    text-shadow: 3px 3px 0 var(--color-secondary);
  }
  100% {
    text-shadow: none;
  }
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(-12px, -12px);
  }
  50% {
    transform: translate(-12px, -12px);
  }
  60% {
    transform: translate(-12px, -12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

// project page ==========================================================
